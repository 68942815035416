import React from 'react'
import {
    Header,
    Loader,
} from 'semantic-ui-react'
import DiffDisplay from '../../../common/diffDisplay'
import { IViqmaDocument } from '../../../../api/documents'
import { useGetQueryByParams } from '../../../../hooks/useGetQueryByParams'
import * as API from '../../../../api'
import { useLanguage } from '../../../../hooks/language'

export interface TemplateVersionModalProps {
    currentDocument: IViqmaDocument
}

const TemplateVersionModal: React.FunctionComponent<TemplateVersionModalProps> = ({
    currentDocument,
}) => {
    const language = useLanguage()
    const { isLoading: versionsLoading, data: version } = useGetQueryByParams(!!currentDocument, API.documents.getTemplateVersionsById, "templateVersions", { id: currentDocument.id, version: currentDocument.version - 1, language })

    if (versionsLoading) return <Loader active={true} inline size="tiny" />

    return (
        <>
            <Header>{currentDocument.name}</Header>
            <DiffDisplay
                oldValue={version?.content ?? ''}
                newValue={currentDocument?.content ?? ''}
            />
        </>
    )
}

export default TemplateVersionModal
