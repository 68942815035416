
import { FileListing } from "../../components/common/files";
import { get, post, destroy, put } from "../restClient";

export enum DeviationMethod {
    Pdca,
    EightD
}
export interface IActivityDto {
    id: number
    activity: string
    description: string
    responsibleId: string
    doneBefore: Date | null
    status: number | null
    doneDate: Date | null
    doneBy: string
    closingNotifyResponsible: boolean
    creationNotifyResponsible: boolean
}
export interface IIssueDto {
    id: number;
    companyId: number;
    ownId: number;
    type: number; // Required
    typeName: string;
    responsibleId: string; // Required, non-empty string
    departmentId: number; // Required
    supplierId?: number | null;
    customerSupplier?: string;
    reportedBy: string; // Required, non-empty string
    reportDate: Date | null;
    topic: string; // Required, non-empty string
    description?: string;
    actionsTaken?: string;
    analysis: string | null;
    resolvingAction: string | null;
    cost: number | null;
    time: number | null;
    doneBefore: Date | null;
    useReview?: boolean; // Default to true
    reviewDate: Date | null;
    reviewResponsibleId: string;
    reviewBeforeDate: Date | null;
    reviewText: string; // Default to ""
    status: number;
    endedDate: Date | null;
    endedBy?: string; // Default to ""
    tSedel?: string;
    vehicleNumber?: string;
    traktNamn?: string;
    plannedDepartment?: string;
    contractorEmail?: string;
    woodOrder?: string;
    responseDate?: Date | null;
    projectId?: number | null;
    errorCode?: number | null;
    errorName?: string | null;
    causeCode?: number | null;
    causeName?: string | null;
    extraInformation1?: string;
    extraInformation2?: string;
    sent: Date | null;
    sentBy?: string;
    replied: boolean | null;
    supplierShouldReply: boolean | null;
    locationId?: number | null;
    reminderCount: number;
    extraFields: { [key: string]: string | string[] };
    teamMembers: string[]
    deviationMethod: DeviationMethod
    desiredEffect: boolean
    desiredEffectComment: string
}

export enum IssueHierarchyType {
    DeviationType,
    ErrorCode,
    CauseCode
}
export interface IssueTypeHierarchy {
    id: number
    name: string
    description: string
    parentId: number
    issueHierarchyType: IssueHierarchyType
    isUsed: boolean
    subRows: IssueTypeHierarchy[];
}
export interface IssueType {
    id: number
    name: string
    description: string
}

export interface IssueTypeRequest {
    id: number
    parentId: number | null
    name: string
    description: string
}

export interface IssueSetting {
    id: number
    extraInformation1FieldName: string | null
    extraInformation2FieldName: string | null
}

export interface IAccidentAuthoritySetting {
    id: number
    companyId: number
    message: string
    link: string
}

export interface IEvaluateTypes {
    reviewDate: Date | null;
    reviewBeforeDate: Date | null;
    reviewResponsibleId: string;
    reviewText: string;
}

export interface IIssueRequest extends IEvaluateTypes {
    type: number | null
    errorCode?: number | null
    causeCode?: number | null
    topic: string
    departmentId: number | null
    locationId?: number | null
    responsibleId: string
    supplierId?: number | null
    customerSupplier?: string
    doneBefore?: Date | null
    description?: string
    actionsTaken?: string
    reportedBy: string
    reportDate: Date | null
    extraInformation1?: string,
    extraInformation2?: string,
    useReview: boolean
    endedDate: Date | null;
    endedBy?: string;
    analysis: string | null;
    resolvingAction: string | null
    cost: number | null;
    time: number | null;
    feedback: boolean
    sendEmailToResponsible: boolean
    sent: Date | null;
    replied: boolean | null;
    supplierShouldReply: boolean | null;
    alternativeEmail: string | null
    sendDeviationManagementEmail: boolean
    extraFields: { [key: string]: string | string[] };
    teamMembers: string[]
    deviationMethod: DeviationMethod
    desiredEffect: boolean
    desiredEffectComment: string
    sendEmailToTeamMembers: boolean
    sendEightDReportToTeamMembers: boolean
}

export interface IAccidentDto {
    id: number;
    companyId: number;
    ownId: number;
    type: number;
    accidentSubtype?: number | null
    isReported: boolean;
    responsibleId: string;
    safetyOfficerId?: string | null;
    departmentId: number;
    reportedBy: string;
    reportDate: Date | null;
    extentOfOutcome: string | null;
    accidentDate: Date | null;
    accidentTime: string | null;
    accidentName: string | null;
    employmentType: number | null;
    employmentYear: number | null;
    whatHappened: number | null;
    location: string | null;
    task: string | null;
    factor: string | null;
    differencesFromNormal: string | null;
    conditionalEffects: string | null;
    locationHadImpact: boolean;
    equipmentHadImpact: boolean;
    workEnvironmentHadImpact: boolean;
    preventiveActionsHadImpact: boolean;
    companyClimateHadImpact: boolean;
    educationHadImpact: boolean;
    otherCausesHadImpact: boolean;
    estimatedAbsenceTime: number | null;
    description: string | null;
    actionsTaken: string | null;
    analysis: string | null;
    resolvingAction: string | null;
    doneBefore: Date | null;
    useReview: boolean | null;
    endedDate: Date | null;
    endedBy: string | null;
    status: number;
    reviewText: string | null;
    reviewDate: Date | null;
    reviewResponsibleId: string | null;
    reviewBeforeDate: Date | null;
    projectId: number | null;
    customerSupplier: string | null;
    locationId: number | null;
    extraFields: { [key: string]: string | string[] };
}

export interface AccidentWithCreationOptionsDto extends IAccidentDto {
    sendEmailToResponsible: boolean
    sendEmailToSafetyOfficer: boolean
    feedback: boolean
}

export interface IDeviationSettingUser {
    id: number
    userId: string
    companyId: number
    deviationResponsible: boolean | null
    accidentResponsible: boolean | null
    safetyOfficer: boolean | null
}

export interface IResponsilbeSafetyBatchRequest {
    userIds: string[]
    deviationResponsible: boolean
    accidentResponsible: boolean
    safetyOfficer: boolean
}

export interface IExtraField {
    type: number;
    required: boolean;
}

export interface IExtraFieldTemplate {
    id: number;
    extraFieldAsJson: string;
    companyId: number;
    moduleCollection: number;
    extraFields: { [key: string]: IExtraField };
}

export interface IExtraFieldRequest {
    moduleCollection: number;
    extraFields: { [key: string]: IExtraField };
}
export interface IEditExtraFieldRequest {
    moduleCollection: number
    oldKey: string
    newKey: { [key: string]: IExtraField };
}
export enum ModuleCollection {
    Deviations = 0,
    Accidents = 1,
}

export interface IStatisticBase {
    count: number;
    year: number;
    month: number
    department: string
    locationId: number

}
export interface IDeviationStatisticsResponse extends IStatisticBase {
    reason: string;
    handledInTime: number;
    handledToLate: number;
    errorCode: number
    causeCode: number
}

export interface IAccidentStatisticsResponse extends IStatisticBase {
    type: number;
    accidentSubtype: number | null
}
export interface IEffenciencyDeviationStatistic extends IStatisticBase {
    averageDaysUntilResolve: number
    handledInTime: number;
    handledToLate: number;
}

export interface ICostDeviation extends IStatisticBase {
    laborCost: number
    materialCost: number
}

export interface IAccidentStatisticEmploymentResponse extends IStatisticBase {
    employmentType: number
}
export interface IAccidentStatisticAbsenceResponse extends IStatisticBase {
    absenceTimeType: number
}
export interface IAccidentStatisticCauseResponse extends IStatisticBase {
    causeOfAccident: number
}
export interface ICompanyAccidentSubtype {
    id: number;
    companyId: number;
    typeId: number;
    name: string;
}
export interface IAccidentType {
    id: number;
    name: string;
    companyAccidentSubtypes?: ICompanyAccidentSubtype[];
}

export interface IAccidentSubtypeRequest {
    typeId: number;
    name: string;
}

export const getAll = () => get<IIssueDto[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue`)

export const getById = (id: number) => get<IIssueDto>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/${id}`)

export const getDeviationTypes = () => get<IssueType[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/type`)

export const getTypeErrorCodes = (typeId: number) => get<IssueType[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/errorcode/${typeId}`)

export const getTypeErrorCauseCodes = (errorId: number) => get<IssueType[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/causecode/${errorId}`)

export const getIssueActivitiesById = (id: number) => get<IActivityDto[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/${id}/activity`)

export const getIssueActivityById = (issueId: number, activityId: number) => get<IActivityDto>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/${issueId}/activity/${activityId}`)

export const createIssueActivity = (id: number, data: IActivityDto) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/${id}/activity`, data)

export const editIssueActivity = (issueId: number, activityId: number, data: IActivityDto) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/${issueId}/activity/${activityId}`, data)

export const create = (data: IIssueRequest) => post<IIssueDto>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue`, data)

export const edit = (id: number, data: IIssueRequest) => put<IIssueDto>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/${id}`, data)

export const notifyIssueFeedback = (id: number) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/${id}/notify/feedback`)

export const notifyIssueReminder = (id: number) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/${id}/notify/reminder`)

export const notifyIssueEvaluateReminder = (id: number) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/${id}/notify/evaluate/reminder`)

export const notifyIssueActivity = (issueId: number, activityId: number) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/${issueId}/activity/${activityId}/notify`)

export const destroyIssue = (id: number) => destroy(`${window.env.REACT_APP_ISSUE_SERVICE}/api/issue/${id}`)

export const getAllAccidents = () => get<IAccidentDto[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident`)

export const getAccidentById = (id: number) => get<IAccidentDto>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident/${id}`)

export const createAccident = (data: AccidentWithCreationOptionsDto) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident`, data)

export const editAccident = (id: number, data: AccidentWithCreationOptionsDto) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident/${id}`, data)

export const getAccidentActivitiesById = (id: number) => get<IActivityDto[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident/${id}/activity`)

export const getAccidentActivityById = (accidentId: number, activityId: number) => get<IActivityDto>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident/${accidentId}/activity/${activityId}`)

export const createAccidentActivity = (id: number, data: IActivityDto) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident/${id}/activity`, data)

export const editAccidentActivity = (accidentId: number, activityId: number, data: IActivityDto) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident/${accidentId}/activity/${activityId}`, data)

export const notifyAccidentActivity = (accidentId: number, activityId: number) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident/${accidentId}/activity/${activityId}/notify`)

export const notifyAccidentReminder = (id: number) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident/${id}/notify/reminder`)

export const notifyAccidentEvaluateReminder = (id: number) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident/${id}/notify/evaluate/reminder`)

export const notifyAccidentFeedback = (id: number) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident/${id}/notify/feedback`)

export const destroyAccident = (id: number) => destroy(`${window.env.REACT_APP_ISSUE_SERVICE}/api/accident/${id}`)

export const getAllIssueFiles = (issueId: string) => get<FileListing[]>(`${window.env.REACT_APP_FILE_SERVICE}/api/File/Issues/${issueId}`)

export const getAllAccidentFiles = (accidentId: string) => get<FileListing[]>(`${window.env.REACT_APP_FILE_SERVICE}/api/File/IssuesAccidents/${accidentId}`)

export const getDeviationTypesHierarchy = () => get<IssueTypeHierarchy[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/type/hierarchy`)

export const createIssueType = (data: IssueType) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/type`, data)

export const editIssueType = (typeId: number, data: IssueType) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/type/${typeId}`, data)

export const destroyIssueType = (id: number) => destroy(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/type/${id}`)

export const createIssueErrorCode = (typeId: number, data: IssueType) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/type/${typeId}/error`, data)

export const editIssueErrorCode = (errorId: number, data: IssueType) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/error/${errorId}`, data)

export const destroyIssueErrorCode = (id: number) => destroy(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/error/${id}`)

export const createIssueCauseCode = (errorId: number, data: IssueType) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/error/${errorId}/cause`, data)

export const editIssueCauseCode = (causeId: number, data: IssueType) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/cause/${causeId}`, data)

export const destroyIssueCauseCode = (id: number) => destroy(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/cause/${id}`)

export const getAccidentAuthoritySetting = () => get<IAccidentAuthoritySetting>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/accident/authority`)

export const createAccidentAuthority = (data: IAccidentAuthoritySetting) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/accident/authority`, data)

export const editAccidentAuthority = (id: number, data: IAccidentAuthoritySetting) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/accident/authority/${id}`, data)

export const getUserSettings = () => get<IDeviationSettingUser[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/user`)

export const batchAddUserSettings = (data: IResponsilbeSafetyBatchRequest) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/user/batch`, data)

export const editUserSettings = (data: IDeviationSettingUser) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/user`, data)

export const destroyUserSetting = (id: number) => destroy(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/user/${id}`)

export const getExtraFields = (moduleCollection: ModuleCollection) => get<IExtraFieldTemplate>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/extrafields/${moduleCollection}`)

export const createExtraFields = (data: IExtraFieldRequest) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/extrafields/batch`, data)

export const batchEditNewExtra = (settingId: number, data: IExtraFieldRequest) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/extrafields/batch/${settingId}`, data)

export const destroyExtraField = (id: number, key: string) => destroy(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/extrafields/${id}/${key}`)

export const editNewExtra = (settingId: number, data: IEditExtraFieldRequest) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/extrafields/${settingId}`, data)

export const sendEightDReport = (data: any) => post(`${window.env.REACT_APP_NOTIFICATION_SERVICE}/api/Notification`, data)

export const getIssuesPerYear = () => get<IDeviationStatisticsResponse[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/issues/year`)

export const postIssuesPerSelectedYear = (data: any) => post<IDeviationStatisticsResponse[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/issues`, data)

export const postAccidentsFilteredStats = (data: any) => post<IAccidentStatisticsResponse[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/accidents`, data)

export const postIssuesEffeciencyFilteredStats = (data: any) => post<IEffenciencyDeviationStatistic[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/issues/effeciency`, data)

export const postIssuesCostFilteredStats = (data: any) => post<ICostDeviation[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/issues/cost`, data)

export const postAccidentsEmploymentFormFilteredStats = (data: number[]) => post<IAccidentStatisticEmploymentResponse[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/accidents/employment`, data)

export const postAccidentsAbsenceFilteredStats = (data: number[]) => post<IAccidentStatisticAbsenceResponse[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/accidents/absence`, data)

export const postAccidentsCauseFilteredStats = (data: number[]) => post<IAccidentStatisticCauseResponse[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/Statistics/accidents/cause`, data)

export const getAccidentTypes = () => get<IAccidentType[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/accident/type`)

export const getAccidentSubtypes = () => get<ICompanyAccidentSubtype[]>(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/accident/subtype`)

export const postAccidentSubtype = (data: IAccidentSubtypeRequest) => post(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/accident/subtype`, data)

export const editAccidentSubtype = (subtypeId: number, data: IAccidentSubtypeRequest) => put(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/accident/subtype/${subtypeId}`, data)

export const destroyAccidentSubtype = (subtypeId: number) => destroy(`${window.env.REACT_APP_ISSUE_SERVICE}/api/setting/accident/subtype/${subtypeId}`)