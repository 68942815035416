import React from 'react'
import { Button, Form, Grid, GridColumn, GridRow, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import * as API from '../../../../../api'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import { getState } from '../../../../../state'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { ICompanyDeviationSettingsRequest } from '../../../../../api/companies'
import { zodResolver } from '@hookform/resolvers/zod'
import { handleAxiosError } from '../../../../../actions/error'
import { toast } from 'react-toastify'
import { useMutation } from 'react-query'
import { Save } from 'react-feather'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import ControlCheckbox from '../../../../fields/ControlCheckbox'
import globalCompanyInfoStartupAction from '../../../../../actions/company'

export const companyDeviationSettingsRequestSchema = z.object({
    showDeviationPopUp: z.boolean(),
});

const CompanyDeviationSettings = () => {
    const { t } = useTranslation()
    const { state, dispatch } = getState()

    const { control, handleSubmit } = useForm<ICompanyDeviationSettingsRequest>({
        resolver: zodResolver(companyDeviationSettingsRequestSchema),
        defaultValues: { showDeviationPopUp: state.company?.showDeviationPopUp || false }
    });

    const onStatisticConfigurationRequest = async (data: ICompanyDeviationSettingsRequest) => await API.companies.editCompanyDeviationSettings(data)

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onStatisticConfigurationRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            globalCompanyInfoStartupAction(dispatch)
        },
    })
    return (
        <FullHeightStackingContainer>
            <Message info>
                <Message.Header>
                    {t('deviationSettings.companySettingsHeader')}
                </Message.Header>
                {t('deviationSettings.companySettingsMessageBody')}
            </Message>
            <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
                <Grid stackable>
                    <GridRow columns={3}>
                        <GridColumn>
                            <ControlCheckbox popupText={t('tooltip.showDeviationPopUp')} name="showDeviationPopUp" label={t('showDeviationPopUp')} control={control} disabled={isSaving} />
                        </GridColumn>
                    </GridRow>
                    <GridRow >
                        <GridColumn verticalAlign='bottom'>
                            <Button
                                primary
                                floated='right'
                                content={t('save')}
                                icon={
                                    <SemanticFeatherIcon
                                        FeatherIcon={Save}
                                    />
                                }
                                type={'submit'}
                                disabled={isSaving}
                            />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </Form>
        </FullHeightStackingContainer>
    )
}

export default CompanyDeviationSettings
