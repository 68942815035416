import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import * as z from 'zod';
import ControlInput from '../../fields/ControlInput';
import { getState } from '../../../state';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api'
import { useMutation } from 'react-query';
import { handleAxiosError } from '../../../actions/error';
import { toast } from 'react-toastify';
import { IAccidentSubtypeRequest, IAccidentType } from '../../../api/issues';

import FormActions from '../FormActions';

interface IAccidentSubtypeFormProps {
    onCancel: () => void
    refresh: () => void
    isNew: boolean
    selectedAccidentType: IAccidentType | null
}

export const accidentSubtypeRequestSchema = z.object({
    typeId: z.number(),
    name: z.string().min(1, "required"),
});

const AccidentSubtypeForm = ({
    onCancel,
    refresh,
    isNew,
    selectedAccidentType,
}: IAccidentSubtypeFormProps) => {
    const { t } = useTranslation()
    const {
        dispatch,
    } = getState()

    const { control, handleSubmit } = useForm<IAccidentSubtypeRequest>({
        resolver: zodResolver(accidentSubtypeRequestSchema),
        defaultValues: selectedAccidentType && !isNew ? { typeId: selectedAccidentType?.id, name: selectedAccidentType?.name } : { typeId: selectedAccidentType?.id, name: "" }
    });

    const onAccidentSubtypeRequest = async (data: IAccidentSubtypeRequest) => {
        if (isNew) {
            return await API.issues.postAccidentSubtype(data)
        } else {
            return await API.issues.editAccidentSubtype(data.typeId, data)
        }
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onAccidentSubtypeRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async () => {
            toast.success(t("saved"))
            refresh()
            onCancel()
        },
    })

    return (

        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            <Grid stackable>
                <GridRow columns="2">
                    <GridColumn >
                        <ControlInput name="name" label={t('name')} control={control} required disabled={isSaving} />
                    </GridColumn>

                </GridRow>
            </Grid>
            <FormActions disabled={isSaving} onCancel={onCancel} />
        </Form>
    );
};

export default AccidentSubtypeForm;
