import { Editor as EditorComponent } from '@tinymce/tinymce-react'
import { AxiosError } from 'axios'
import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimmer, Loader } from 'semantic-ui-react'
import styled from 'styled-components'
import { Editor } from 'tinymce/tinymce'
import { getAuthedAxios } from '../../../../../auth'
import * as API from '../../../../../api'

const EditorContainer = styled.div`
    min-height: 500px;
`

export interface EditDocumentProps {
    value?: string
    initialValue?: string
    onChange?: (text: string) => void
    readOnly?: boolean
    imageUploaded?: (fileId: string) => void
    onEditorInit?: (editor: Editor) => void
    imageUploadSettings?: ImageUploadSettings
}

export interface ImageUploadSettings {
    overrideCompanyId: number
}

const imagesUploadHandler = async (blobInfo: any, imageUploadSettings?: ImageUploadSettings): Promise<string> => {
    const file = blobInfo.blob();

    try {
        const axios = await getAuthedAxios();

        const formData = new FormData();
        formData.append('file', file, blobInfo.filename());

        const uploadUrl = imageUploadSettings?.overrideCompanyId
            ? `${window.env.REACT_APP_FILE_SERVICE}/api/File/Documents/document-images?overrideCompanyId=${imageUploadSettings.overrideCompanyId}`
            : `${window.env.REACT_APP_FILE_SERVICE}/api/File/Documents/document-images`;

        const response = await axios.post(uploadUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        const { id } = response.data;

        if (id) {
            const imageUrl = `${window.env.REACT_APP_FILE_SERVICE}/api/File/${id}`
            console.log("Uploaded image URL:", imageUrl);
            return imageUrl; // Returnera URL:en för att TinyMCE ska kunna använda den
        } else {
            const errorMessage = 'Upload failed, invalid response from server.';
            console.error(errorMessage);
            throw new Error(errorMessage);
        }
    } catch (error: any) {
        const errorMessage = `An error occurred during upload: ${error.message}`;
        console.error(errorMessage);
        throw new Error(errorMessage); // Avbryt uppladdningen med fel
    }
};
const EditDocument: FunctionComponent<EditDocumentProps> = ({
    value,
    initialValue,
    onChange,
    imageUploaded,
    onEditorInit,
    imageUploadSettings,
    readOnly = false,
}) => {
    const { i18n, t } = useTranslation()
    const [editorLoaded, setEditorLoaded] = useState(false)

    const handleChange = (content: string) => {
        onChange?.(content)
    }
    const defaultAiShortCuts = [
        { title: t('Summarize content'), prompt: 'Provide the key points and concepts in this content in a succinct summary.', selection: true },
        { title: t('Improve writing'), prompt: 'Rewrite this content with no spelling mistakes, proper grammar, and with more descriptive language, using best writing practices without losing the original meaning.', selection: true },
        { title: t('Simplify language'), prompt: 'Rewrite this content with simplified language and reduce the complexity of the writing, so that the content is easier to understand.', selection: true },
        { title: t('Expand upon'), prompt: 'Expand upon this content with descriptive language and more detailed explanations, to make the writing easier to understand and increase the length of the content.', selection: true },
        { title: t('Trim content'), prompt: 'Remove any repetitive, redundant, or non-essential writing in this content without changing the meaning or losing any key information.', selection: true },
        {
            title: t('Change tone'), subprompts: [
                { title: t('Professional'), prompt: 'Rewrite this content using polished, formal, and respectful language to convey professional expertise and competence.', selection: true },
                { title: t('Casual'), prompt: 'Rewrite this content with casual, informal language to convey a casual conversation with a real person.', selection: true },
                { title: t('Direct'), prompt: 'Rewrite this content with direct language using only the essential information.', selection: true },
            ]
        },
        {
            title: t('Change style'), subprompts: [
                { title: t('Business'), prompt: 'Rewrite this content as a business professional with formal language.', selection: true },
                { title: t('Legal'), prompt: 'Rewrite this content as a legal professional using valid legal terminology.', selection: true },
            ]
        },
        {
            title: t('Translate'), subprompts: [
                { title: t('Translate to English'), prompt: 'Translate this content to English language.', selection: true },
                { title: t('Translate to Spanish'), prompt: 'Translate this content to Spanish language.', selection: true },
                { title: t('Translate to German'), prompt: 'Translate this content to German language.', selection: true },
                { title: t('Translate to French'), prompt: 'Translate this content to French language.', selection: true },
                { title: t('Translate to Norwegian'), prompt: 'Translate this content to Norwegian language.', selection: true },
                { title: t('Translate to Polish'), prompt: 'Translate this content to Polish language.', selection: true },
            ]
        },
    ]
    const aiRequestHandler = (request: { prompt: string }, respondWith: any) => {
        respondWith.string(async (signal: any) => {
            try {
                const data = await API.documents.openAIRequest(request)
                return data?.data?.content || "No content received.";
            } catch (error) {
                console.error("AI Request Error:", error);
                return "Unable to process your request at the moment. Please try again later.";
            }
        });
    };
    return (
        <EditorContainer>
            <Dimmer active={!editorLoaded} inverted>
                <Loader />
            </Dimmer>

            <EditorComponent
                apiKey="lm80ja5wkxtullx74vaictayyplm5ci9kzswymg3tvqfwkwq"
                value={value}
                initialValue={initialValue}
                onEditorChange={handleChange}
                onInit={(_, editor) => {
                    setEditorLoaded(true)
                    onEditorInit?.(editor)
                }}
                disabled={readOnly}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'ai',
                        'advlist',
                        'autolink',
                        'lists',
                        'image',
                        'searchreplace',
                        'visualblocks',
                        'code',
                        'fullscreen',
                        'table',
                        'help',
                        'wordcount',
                        'link',
                    ],
                    toolbar:
                        'aidialog aishortcuts | undo redo | formatselect | bold italic underline backcolor forecolor  | ' +
                        'alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist outdent indent | table link unlink image | ' +
                        'removeformat | help code',
                    content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    entity_encoding: 'raw',

                    automatic_uploads: false,
                    images_upload_handler: (blobInfo) => imagesUploadHandler(blobInfo, imageUploadSettings),
                    file_picker_types: 'image',
                    images_file_types: 'jpeg,jpg,jpe,png,gif,bmp,webp',
                    images_reuse_filename: false,
                    language: i18n.language === 'sv' || i18n.language === 'sv-SE' ? 'sv_SE' : 'en',
                    browser_spellcheck: true,
                    contextmenu: false,
                    branding: false,
                    ai_request: aiRequestHandler,
                    ai_shortcuts: [
                        ...defaultAiShortCuts
                    ],
                }}
            />
        </EditorContainer>
    )
}

export default EditDocument
