import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Grid, GridColumn, GridRow, Message, Segment } from 'semantic-ui-react';
import ControlDropdown from '../../fields/ControlDropdown';
import ControlCheckbox from '../../fields/ControlCheckbox';
import { AccidentWithCreationOptionsDto, IAccidentDto, IDeviationSettingUser, ModuleCollection } from '../../../api/issues';
import * as z from 'zod';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getState } from '../../../state';
import { mockedAccident } from '../../../utils/MockedData/Issues';
import ControlDateField from '../../fields/ControlDateField';
import ControlTextArea from '../../fields/ControlTextArea';
import ControlInput from '../../fields/ControlInput';
import ControlRadioGroup from '../../fields/ControlRadioGroup';
import * as API from '../../../api'
import EvaluateSection from '../common/EvaluateSection';
import CloseSection from '../common/CloseSection';
import { useIsInRole } from '../../../hooks/role';
import { AdminRoles } from '../../../auth/roles';
import CollapsingSegment from '../../common/collapsingSegment';
import ActivityList from '../common/ActivityList';
import Files, { UploadAllPendingFilesCallback } from '../../common/files';
import { handleAxiosError } from '../../../actions/error';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { mapAccidentToRequest } from '../../../utils/Mappings/Issues';
import { useGetQueryByParams } from '../../../hooks/useGetQueryByParams';
import Loading from '../../common/Loading';
import styled from 'styled-components';
import SaveCancelPrintButtonGroup from '../../common/saveCancelPrintButtonGroup';
import AccidentPrintView from './AccidentPrintView';
import { useAccidentUsers } from './useAccidentUsers';
import ControlExtraField from '../../fields/ControlExtraField';

const StyledGridRow = styled(GridRow)`
    margin: 1em 0;
`
const StyledMessageSpan = styled.span`
    word-wrap: break-word;
    white-space: pre-wrap;
`
interface IAccidentFormProps {
    isNew: boolean
    responsibleSafetyUsers: IDeviationSettingUser[]
    refresh?: () => void
    selectedAccident?: IAccidentDto
}

export const accidentSchema = z.object({
    type: z.number(),
    accidentSubtype: z.number().optional().nullable(),
    isReported: z.boolean(),
    responsibleId: z.string().min(1, "required"),
    safetyOfficerId: z.string().optional().nullable(),
    departmentId: z.coerce.number().min(1, { message: "required" }),
    reportedBy: z.string().min(1, "required"),
    reportDate: z.date({ required_error: "required", invalid_type_error: "required", }),
    extentOfOutcome: z.string().min(1, "required"),
    accidentDate: z.date().nullable(),
    accidentTime: z.string().nullable(),
    accidentName: z.string().nullable(),
    employmentType: z.number().nullable(),
    employmentYear: z.coerce.number().int("noDecimals").min(0, { message: "noNegative" }).nullable(),
    whatHappened: z.number().nullable(),
    location: z.string().nullable(),
    task: z.string().nullable(),
    factor: z.string().nullable(),
    differencesFromNormal: z.string().nullable(),
    conditionalEffects: z.string().nullable(),
    locationHadImpact: z.boolean(),
    equipmentHadImpact: z.boolean(),
    workEnvironmentHadImpact: z.boolean(),
    preventiveActionsHadImpact: z.boolean(),
    companyClimateHadImpact: z.boolean(),
    educationHadImpact: z.boolean(),
    otherCausesHadImpact: z.boolean(),
    estimatedAbsenceTime: z.number().nullable(),
    description: z.string().nullable(),
    actionsTaken: z.string().nullable(),
    analysis: z.string().nullable(),
    resolvingAction: z.string().nullable(),
    doneBefore: z.date().nullable(),
    useReview: z.boolean().nullable(),
    endedDate: z.date().nullable(),
    endedBy: z.string().nullable(),
    status: z.number(),
    reviewText: z.string().nullable(),
    reviewDate: z.date().nullable(),
    reviewResponsibleId: z.string().nullable(),
    reviewBeforeDate: z.date().nullable(),
    projectId: z.number().nullable(),
    customerSupplier: z.string().nullable(),
    locationId: z.coerce.number().nullable(),
    sendEmailToResponsible: z.boolean(),
    sendEmailToSafetyOfficer: z.boolean(),
    feedback: z.boolean(),
    extraFields: z.any(),
});

const AccidentForm = ({ responsibleSafetyUsers, refresh, isNew, selectedAccident }: IAccidentFormProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const accidentPrintComponentRef = useRef<HTMLDivElement>(null);
    const {
        dispatch,
        state: { locations, departments, userProfile },
    } = getState()
    const { isInRole: isAdmin } = useIsInRole(AdminRoles.IssuesAdminRole)
    const [uploadAllPendingFiles, setUploadAllPendingFiles] =
        useState<UploadAllPendingFilesCallback>()

    const {
        isLoading: isLoadingSpecialProject,
        data: specialProjects,
    } = useGetQueryByParams(true, API.projects.getAllSpecialProjects, "specialProjects")

    const {
        isLoading: isLoadingExtra,
        data: extraFields,
    } = useGetQueryByParams(true, API.issues.getExtraFields, "accidentExtra", ModuleCollection.Accidents)

    const {
        isLoading: isLoadingTypes,
        data: types
    } = useGetQueryByParams(true, API.issues.getAccidentTypes, "accidentTypes")

    const {
        isLoading,
        data: accidentAuthority,
    } = useGetQueryByParams(true, API.issues.getAccidentAuthoritySetting, "accidentAuthoritySettings")


    const { accidentResponsibleOptions, userOptions, safetyOfficerOptions } = useAccidentUsers(responsibleSafetyUsers, isNew, selectedAccident)

    const { control, handleSubmit, watch, setValue, clearErrors, setError } = useForm<AccidentWithCreationOptionsDto>({
        resolver: zodResolver(accidentSchema),
        defaultValues: selectedAccident ? mapAccidentToRequest(selectedAccident) : mockedAccident(userProfile)
    });

    const validateExtraFields = (data: {
        [key: string]: string | string[];
    }): boolean => {
        if (extraFields?.extraFields) {
            let hasError = false;
            Object.keys(extraFields?.extraFields).forEach(key => {
                if (extraFields?.extraFields[key].required && !data[key]) {
                    setError(`extraFields.${key}`, {
                        type: "required",
                        message: "required"
                    });
                    hasError = true;
                } else {
                    clearErrors(`extraFields.${key}`);
                }
            });
            return !hasError;
        }
        return true
    };

    const onAccidentRequest = async (data: AccidentWithCreationOptionsDto) => {
        if (validateExtraFields(data.extraFields)) {
            if (isNew) {
                return await API.issues.createAccident(data)
            } else {
                let result;
                if (data.feedback) {
                    result = await API.issues.editAccident(selectedAccident!.id, data)
                    await API.issues.notifyAccidentFeedback(selectedAccident!.id)
                } else {
                    result = await API.issues.editAccident(selectedAccident!.id, data)
                }
                return result;
            }
        }
    }

    const { isLoading: isSaving, mutate: onSubmit } = useMutation(onAccidentRequest, {
        onError: (error: any) => {
            handleAxiosError(dispatch)(error)
        },
        onSuccess: async (data) => {
            if (data) {
                if (uploadAllPendingFiles) await uploadAllPendingFiles((data.data as any).id?.toString())
                toast.success(t("saved"))
                if (refresh) refresh()
                navigate(-1)
            }

        },
    })

    const onHandleNewIssueResponsible = (value: string) => {
        if (isNew) {
            setValue("reviewResponsibleId", value)
        }
        setValue("responsibleId", value)
        clearErrors("responsibleId")
    }
    const onHandleChangeIssueType = (value: number) => {
        setValue("type", value)
        setValue("accidentSubtype", null)
    }

    if (isLoadingSpecialProject || isLoading || isLoadingExtra || isLoadingTypes) {
        return <Loading />
    }

    return (
        <Form onSubmit={handleSubmit(data => onSubmit(data))} noValidate>
            {!isNew &&
                <AccidentPrintView
                    ref={accidentPrintComponentRef}
                    accident={watch()}
                    accidentId={selectedAccident?.id}
                    ownId={selectedAccident?.ownId}
                    isAccident={watch("type") === 0 && isAdmin}
                />}

            <SaveCancelPrintButtonGroup
                location="top"
                onCancel={() => navigate(-1)}
                disableCancel={isSaving}
                disableSave={isSaving}
                disablePrint={isSaving || isNew}
                printComponentRef={accidentPrintComponentRef}
            />

            <Grid stackable>
                <GridRow columns="1">
                    <GridColumn >
                        <Message info>
                            {accidentAuthority?.message && accidentAuthority?.link ? <StyledMessageSpan>{accidentAuthority.message} < a target="_blank" rel="noopener noreferrer" href={accidentAuthority.link}>{accidentAuthority.link}</a></StyledMessageSpan>
                                : <span>{t('workAuthorityForm')} <a target="_blank" rel="noopener noreferrer" href='https://anmalarbetsskada.se/'>https://anmalarbetsskada.se/</a></span>}
                        </Message>

                    </GridColumn>
                </GridRow>
                <GridRow columns="1">
                    <GridColumn >
                        <ControlCheckbox name="isReported" label={t('incidentIsReported')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn >
                        <ControlDropdown
                            name="type"
                            label={t('type')}
                            control={control}
                            required
                            collection={types?.map(d => ({ value: d.id, text: t(`accidentReason.${d.id}`) })) ?? []}
                            disabled={isSaving}
                            overrideOnChange={(value) => onHandleChangeIssueType(value)} />
                    </GridColumn>
                    {types?.length ? (
                        <GridColumn>
                            <ControlDropdown
                                name="accidentSubtype"
                                label={t('accidentSubtype')}
                                control={control}
                                collection={
                                    types[watch("type")]?.companyAccidentSubtypes?.map(d => ({
                                        value: d.id,
                                        text: d.name
                                    })) ?? []
                                }
                                disabled={isSaving}
                            />
                        </GridColumn>
                    ) : null}
                </GridRow>

                <GridRow columns="2">
                    <GridColumn>
                        <ControlDropdown name="reportedBy" label={t('reportedBy')} control={control} required collection={userOptions} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn>
                        <ControlDateField name="reportDate" label={t('reportDate')} control={control} required disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn >
                        <ControlDropdown name="departmentId" label={t('departmentId')} control={control} required collection={departments.map(d => ({ value: d.id, text: d.name })) ?? []} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        {locations && locations.length ? <ControlDropdown name="locationId" label={t('locationId')} control={control} collection={locations?.map(d => ({ value: d.id, text: d.name })) ?? []} disabled={isSaving} clearable /> : null}
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn >
                        <ControlDropdown name="responsibleId" label={t('responsibleId')} control={control} required collection={accidentResponsibleOptions.length ? accidentResponsibleOptions : userOptions} disabled={isSaving} overrideOnChange={(value) => onHandleNewIssueResponsible(value)} />
                    </GridColumn>
                    {isNew && <GridColumn verticalAlign='middle'>
                        <ControlCheckbox name="sendEmailToResponsible" label={t('sendEmailToResponsible')} control={control} disabled={isSaving} />
                    </GridColumn>}
                </GridRow>

                {safetyOfficerOptions?.length ? <GridRow columns="2">
                    <GridColumn >
                        <ControlDropdown name="safetyOfficerId" label={t('safetyOfficerId')} control={control} collection={safetyOfficerOptions} disabled={isSaving} />
                    </GridColumn>
                    {isNew && <GridColumn verticalAlign='middle'>
                        <ControlCheckbox name="sendEmailToSafetyOfficer" label={t('sendEmailToSafetyOfficer')} control={control} disabled={isSaving} />
                    </GridColumn>}
                </GridRow> : null}

                <GridRow columns="2">
                    <GridColumn>
                        <ControlInput name="customerSupplier" label={t('customer')} placeholder={t('placeholder.customerAccident')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlDateField name="doneBefore" label={t('doneBefore')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                {specialProjects && specialProjects.length ?
                    <GridRow columns="2">
                        <GridColumn>
                            <ControlDropdown name="projectId" label={t('specialProject')} control={control} collection={specialProjects?.map(d => ({ value: d.id, text: d.title })) ?? []} disabled={isSaving} />
                        </GridColumn>
                        <GridColumn>

                        </GridColumn>
                    </GridRow>
                    : null}

                <GridRow columns="1">
                    <GridColumn >
                        <ControlInput name="extentOfOutcome" label={t('heading')} control={control} required disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                <GridRow columns="2">
                    <GridColumn >
                        <ControlTextArea name="description" label={t('descriptionAccident')} control={control} disabled={isSaving} />
                    </GridColumn>
                    <GridColumn >
                        <ControlTextArea name="actionsTaken" label={t('actionsTakenInfo')} control={control} disabled={isSaving} />
                    </GridColumn>
                </GridRow>

                {extraFields &&
                    <ControlExtraField control={control} disabled={isSaving} userOptions={userOptions} extraFields={extraFields.extraFields} />
                }

                <StyledGridRow>
                    <GridColumn >
                        <Files
                            displayTitle
                            module="IssuesAccidents"
                            disableDelete={!isAdmin}
                            enableDragDrop
                            mayAutoUpload={!!selectedAccident}
                            collectionId={selectedAccident && selectedAccident.id.toString()}
                            onFilesAdded={selectedAccident ? undefined : (files, uploadAllPendingFiles) => {
                                if (!files.length) return
                                setUploadAllPendingFiles(() => uploadAllPendingFiles)
                            }}
                        />
                    </GridColumn>
                </StyledGridRow>


                {watch("type") === 0 && (isNew || isAdmin) && <GridRow columns="1">
                    <GridColumn >
                        <Segment raised disabled={isSaving} padded>
                            <Grid stackable>
                                <GridRow columns="1">
                                    <GridColumn >
                                        <Message info>
                                            <span>
                                                {t('onlyAccident')}
                                            </span>
                                        </Message>

                                    </GridColumn>
                                </GridRow>
                                <GridRow columns="2">
                                    <GridColumn >
                                        <ControlRadioGroup control={control} name='estimatedAbsenceTime' disabled={isSaving} label={t('estimatedAbsenceTime')}
                                            options={[
                                                { value: 0, text: t('absenceTimeType.0') },
                                                { value: 1, text: t('absenceTimeType.1') },
                                                { value: 2, text: t('absenceTimeType.2') },
                                                { value: 3, text: t('absenceTimeType.3') },
                                                { value: 4, text: t('absenceTimeType.4') },
                                            ]} />
                                    </GridColumn>
                                </GridRow>
                                <GridRow columns="2">
                                    <GridColumn>
                                        <ControlDateField name="accidentDate" label={t('accidentDate')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                    <GridColumn>
                                        <ControlInput name="accidentTime" label={t('accidentTime')} control={control} disabled={isSaving} />
                                    </GridColumn>

                                </GridRow>
                                <GridRow columns="2">
                                    <GridColumn>
                                        <ControlInput name="accidentName" label={t('optionalNameOfTheInjuredPerson')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                    <GridColumn>
                                        <ControlInput name="employmentYear" label={t('employmentYear')} control={control} type="number" disabled={isSaving} />
                                    </GridColumn>

                                </GridRow>

                                <GridRow columns="2">
                                    <GridColumn >
                                        <ControlRadioGroup control={control} name='employmentType' disabled={isSaving} label={t('employmentType')} options={[
                                            { value: 0, text: t('employmentType.0') },
                                            { value: 1, text: t('employmentType.1') },
                                            { value: 2, text: t('employmentType.2') },
                                            { value: 3, text: t('employmentType.3') },
                                            { value: 4, text: t('employmentType.4') },
                                            { value: 5, text: t('employmentType.5') },
                                        ]} />
                                    </GridColumn>
                                    <GridColumn >
                                        <ControlRadioGroup control={control} name='whatHappened' disabled={isSaving} label={t('whatHappened')} options={[
                                            { value: 0, text: t('causeOfAccident.0') },
                                            { value: 1, text: t('causeOfAccident.1') },
                                            { value: 2, text: t('causeOfAccident.2') },
                                            { value: 3, text: t('causeOfAccident.3') },
                                            { value: 4, text: t('causeOfAccident.4') },
                                            { value: 5, text: t('causeOfAccident.5') },
                                            { value: 6, text: t('causeOfAccident.6') },
                                            { value: 7, text: t('causeOfAccident.7') },
                                            { value: 8, text: t('causeOfAccident.8') },
                                            { value: 9, text: t('causeOfAccident.9') },
                                            { value: 10, text: t('causeOfAccident.10') },
                                        ]} />
                                    </GridColumn>
                                </GridRow>
                                <GridRow columns="2">
                                    <GridColumn>
                                        <ControlTextArea name="location" label={t('location')} control={control} disabled={isSaving} placeholder={t('whereDidTheIncidentOccur')} />
                                    </GridColumn>
                                    <GridColumn>
                                        <ControlTextArea name="task" label={t('task')} control={control} disabled={isSaving} placeholder={t('whatWasTheActualTaskWhenTheIncidentOccured')} />
                                    </GridColumn>

                                </GridRow>
                                <GridRow columns="2">
                                    <GridColumn>
                                        <ControlTextArea name="factor" label={t('factor')} control={control} disabled={isSaving} placeholder={t('ifPersonalInjuryWhatCausedIt')} />
                                    </GridColumn>
                                    <GridColumn>
                                        <ControlTextArea name="differencesFromNormal" label={t('differencesFromNormal')} control={control} disabled={isSaving} />
                                    </GridColumn>

                                </GridRow>

                                <h3>{t('incidentConditions')}</h3>

                                <GridRow columns="2">
                                    <GridColumn>
                                        <ControlCheckbox name="locationHadImpact" label={t('locationHadImpact')} upperLabel={t('locationWhereItHappened')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                    <GridColumn>
                                        <ControlCheckbox name="equipmentHadImpact" label={t('equipmentHadImpact')} upperLabel={t('equipmentAndClothes')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                </GridRow>

                                <GridRow columns="2">
                                    <GridColumn>
                                        <ControlCheckbox name="workEnvironmentHadImpact" label={t('workEnvironmentHadImpact')} upperLabel={t('workSituation')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                    <GridColumn>
                                        <ControlCheckbox name="preventiveActionsHadImpact" label={t('preventiveActionsHadImpact')} upperLabel={t('preventiveActions')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                </GridRow>

                                <GridRow columns="2">
                                    <GridColumn>
                                        <ControlCheckbox name="companyClimateHadImpact" label={t('companyClimateHadImpact')} upperLabel={t('securityClimate')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                    <GridColumn>
                                        <ControlCheckbox name="educationHadImpact" label={t('educationHadImpact')} upperLabel={t('educationAndInformation')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                </GridRow>

                                <GridRow columns="1">
                                    <GridColumn>
                                        <ControlCheckbox name="otherCausesHadImpact" label={t('otherCausesHadImpact')} control={control} disabled={isSaving} />
                                    </GridColumn>

                                </GridRow>
                                <GridRow columns="1">
                                    <GridColumn>
                                        <ControlTextArea name="conditionalEffects" label={t('conditionalEffects')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </Segment>
                    </GridColumn>
                </GridRow>}

                <GridRow columns="1">
                    <GridColumn>
                        <CollapsingSegment heading={t('analysisAction')} defaultOpen={!isNew}>
                            <Grid stackable>
                                <GridRow columns="1">
                                    <GridColumn >
                                        <ControlTextArea name="analysis" label={t('analysisInfo')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                </GridRow>
                                <GridRow columns="1">
                                    <GridColumn >
                                        <ControlTextArea name="resolvingAction" label={t('resolvingActionInfo')} control={control} disabled={isSaving} />
                                    </GridColumn>
                                </GridRow>
                                {!isNew && selectedAccident && <GridRow >
                                    <GridColumn >
                                        <ActivityList
                                            deviationId={selectedAccident?.id}
                                            userOptions={userOptions}
                                            localStorageKeys={{
                                                visibility: "accidentActivityListVisibilityState",
                                                grouping: "accidentActivityGroupingVisibility",
                                                pagination: "accidentActivityPaginationState",
                                                show: "accidentActivityShowAllState",
                                                columnFilters: "accidentActivityColumnFiltersState",
                                                global: "accidentActivityGlobalFilterState",
                                                sorting: "accidentActivitySortingState",
                                                expanded: "accidentActivityExpandedState"
                                            }}
                                            apiFunctions={{
                                                listKey: "accidentActivities",
                                                getActivitiesById: API.issues.getAccidentActivitiesById,
                                                notifyActivity: API.issues.notifyAccidentActivity,
                                                editActivity: API.issues.editAccidentActivity,
                                                createActivity: API.issues.createAccidentActivity
                                            }}
                                            notifyClosingTranslationKey='notifyClosingAccidentActivityResponsible'
                                            notifyCreationTranslationKey='notifyCreationActivityResponsible' />
                                    </GridColumn>
                                </GridRow>}
                            </Grid>

                        </CollapsingSegment>

                    </GridColumn>
                </GridRow>

                {!isNew && <GridRow >
                    <GridColumn>
                        <CloseSection
                            control={control}
                            isSaving={isSaving}
                            title={t('closeAccident')}
                            isAdmin={isAdmin}
                            isNew={isNew} />
                    </GridColumn>
                </GridRow>}

                {!isNew && watch("useReview") ? (
                    <GridRow >
                        <GridColumn>
                            <EvaluateSection control={control} isSaving={isSaving} users={userOptions} />
                        </GridColumn>
                    </GridRow>
                ) : null}
                <GridRow columns="1">
                    <GridColumn>
                        <SaveCancelPrintButtonGroup
                            location="bottom"
                            onCancel={() => navigate(-1)}
                            disableCancel={isSaving}
                            disableSave={isSaving}
                            disablePrint={isSaving || isNew}
                            printComponentRef={accidentPrintComponentRef}
                        />
                    </GridColumn>
                </GridRow>
            </Grid>
        </Form>
    );
};

export default AccidentForm;
