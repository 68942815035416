import React, { useMemo, useState } from 'react'
import { Button, Message, Popup } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { ColumnFiltersState, ExpandedState, PaginationState, SortingState, VisibilityState, createColumnHelper, getCoreRowModel, getExpandedRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { IAccidentType } from '../../../../../api/issues'
import SemanticFeatherIcon from '../../../../icons/SemanticFeatherIcon'
import { ChevronDown, ChevronRight, Edit, Plus, Trash2 } from 'react-feather'
import FullHeightStackingContainer from '../../../../common/fullHeightStackingContainer'
import TableReact from '../../../../wrappers/TableReact'
import useTableStorageBackedState from '../../../../wrappers/TableReact/hooks/useTableStorageBackedState'
import { useDestroyAccidentSubtype } from './useDestroyAccidentSubtype'
import { useGetQueryByParams } from '../../../../../hooks/useGetQueryByParams'
import * as API from '../../../../../api'
import Loading from '../../../../common/Loading'
import ModalWrapper from '../../../../wrappers/ModalWrapper'
import AccidentSubtypeForm from '../../../../forms/AccidentSubtypeForm'

interface IAccidentSubtypeListModalState {
    newSubtype: boolean
    editSubtype: boolean
}

type ModalType = "newSubtype" | "editSubtype"

const AccidentSubtypeList = () => {
    const { t } = useTranslation()

    const {
        isLoading,
        data,
        refresh
    } = useGetQueryByParams(true, API.issues.getAccidentTypes, "accidentTypes")

    const [expanded, setExpanded] = useTableStorageBackedState<ExpandedState>("accidentSubtypeExpandedState", {})
    const [columnVisibility, setColumnVisibility] = useTableStorageBackedState<VisibilityState>('accidentSubtypeVisibilityState', {});
    const [pagination, setPagination] = useTableStorageBackedState<PaginationState>('accidentSubtypePaginationState', { pageSize: 10, pageIndex: 0 });
    const [columnFilters, setColumnFilters] = useTableStorageBackedState<ColumnFiltersState>('accidentSubtypeFilteringState', []);
    const [sorting, setSorting] = useTableStorageBackedState<SortingState>('accidentSubtypeSortingState', []);

    const [selectedAccidentType, setSelectedAccidentType] = useState<IAccidentType | null>(null);

    const [modalState, setModalState] = useState<IAccidentSubtypeListModalState>({
        newSubtype: false,
        editSubtype: false,
    });

    const { isDestroying, onDestroy } = useDestroyAccidentSubtype(refresh)

    const columnHelper = createColumnHelper<IAccidentType>()

    const handleOpenModal = (modalType: ModalType, selectedAccidentType: IAccidentType) => {
        setModalState({ ...modalState, [modalType]: true })
        setSelectedAccidentType(selectedAccidentType)
    }

    const handleCloseModal = (modalType: ModalType) => {
        setModalState({ ...modalState, [modalType]: false })
        setSelectedAccidentType(null)
    }

    const deviationTypeColumns = useMemo(
        () => [
            columnHelper.display({
                id: "expandable",
                size: 45,
                enableHiding: false,
                cell: ({ row }) =>
                    <>
                        {row.getCanExpand() && (
                            <Button
                                icon={<SemanticFeatherIcon FeatherIcon={row.getIsExpanded() ? ChevronDown : ChevronRight} />}
                                type="button"
                                onClick={row.getToggleExpandedHandler()}
                            />
                        )}
                    </>
            }),
            columnHelper.accessor("name", {
                id: "name",
                header: t('name') as any,
                size: 250,
                cell: ({ row, getValue }) => (
                    <div style={{ paddingLeft: `${row.depth * 4}rem`, }}>
                        {row.getCanExpand() || !row.depth ? (
                            <>
                                {t(`accidentReason.${row.original.id}`)} ({row.subRows.length})
                            </>
                        ) : getValue()}

                    </div>
                ),
            }),
            columnHelper.display({
                id: "actions",
                size: 140,
                enableHiding: false,
                cell: ({ row }) => {
                    const disableDelete = isDestroying
                    return (
                        <>


                            {row.depth ?
                                <>
                                    <Button title={t('edit')} type='button' onClick={() => handleOpenModal("editSubtype", row.original)} icon={<SemanticFeatherIcon FeatherIcon={Edit} />} />
                                    <Popup
                                        content={disableDelete ? t('popupInfo.deleteDeviationType') : t('delete')}
                                        trigger={
                                            <span>
                                                <Button
                                                    disabled={disableDelete}
                                                    onClick={() => onDestroy({
                                                        confirmText: t('confirm.deleteValue', { value: row.original.name }),
                                                        id: row.original.id
                                                    })}
                                                    type='button'
                                                    color='red'
                                                    icon={<SemanticFeatherIcon FeatherIcon={Trash2} color='white' />} />
                                            </span>
                                        }
                                    />
                                </>
                                : <Button
                                    title={t('addNew')}
                                    onClick={() => handleOpenModal("newSubtype", row.original)}
                                    disabled={isDestroying}
                                    type="button"
                                    primary
                                    icon={<SemanticFeatherIcon FeatherIcon={Plus} />} />}

                        </>
                    )
                }
            }),
        ], [columnHelper, t, isDestroying, onDestroy])

    const table = useReactTable({
        data: data || [],
        columnResizeMode: "onChange",
        columns: deviationTypeColumns,
        state: {
            columnVisibility,
            expanded,
            pagination,
            columnFilters,
            sorting
        },
        enableGlobalFilter: false,
        enableGrouping: false,
        onExpandedChange: setExpanded,
        getSubRows: row => row.companyAccidentSubtypes,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        onPaginationChange: setPagination,
        onColumnFiltersChange: setColumnFilters,
        getExpandedRowModel: getExpandedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    })

    if (isLoading || !data) {
        return <Loading />
    }

    return (
        <FullHeightStackingContainer>
            <Message info>
                <Message.Header>
                    {t('deviationSettings.accidentSubtypeMessageHeader')}
                </Message.Header>
                {t('deviationSettings.accidentSubtypeMessageBody')}
            </Message>

            <TableReact table={table}
                canExport={false}
                overflowable
                hideGroupRowValue={false}
                noRightBorder />

            {selectedAccidentType && <ModalWrapper title={t('addValue', { value: t('accidentSubtype').toLowerCase() })} open={modalState.newSubtype} onClose={() => handleCloseModal("newSubtype")} size='small'>
                <AccidentSubtypeForm isNew onCancel={() => handleCloseModal("newSubtype")} refresh={refresh} selectedAccidentType={selectedAccidentType} />
            </ModalWrapper>}

            {selectedAccidentType && <ModalWrapper title={t('editValue', { value: t('accidentSubtype').toLowerCase() })} open={modalState.editSubtype} onClose={() => handleCloseModal('editSubtype')} size='small'>
                <AccidentSubtypeForm isNew={false} onCancel={() => handleCloseModal("editSubtype")} refresh={refresh} selectedAccidentType={selectedAccidentType} />
            </ModalWrapper>}
        </FullHeightStackingContainer>
    )
}

export default AccidentSubtypeList
