import React, { useMemo } from 'react'
import { Container, Header, Tab, TabPane } from 'semantic-ui-react'
import IssueTypeList from './IssueTypeList'
import { useTranslation } from 'react-i18next'
import ExtraFieldList from './ExtraFieldList'
import AccidentAuthority from './AccidentAuthority'
import { IssueTypeHierarchy, ModuleCollection } from '../../../../api/issues'
import ResponsibleSafetyUserList from './ResponsibleSafetyUserList'
import StatisticConfiguration from './StatisticConfiguration'
import CompanyDeviationSettings from './CompanyDeviationSettings'
import AccidentSubtypeList from './AccidentSubtypeList'
import styled from 'styled-components'
const CustomTab = styled(Tab)`
    .ui.secondary.menu{
        flex-wrap:wrap;
    }
`
interface IModuleSettingsPageProps {
    types: IssueTypeHierarchy[]
    refresh: () => void
}

const ModuleSettingsPage = ({ types, refresh }: IModuleSettingsPageProps) => {
    const { t } = useTranslation()

    const panes = useMemo(() => [
        { menuItem: t("issueType"), render: () => <TabPane><IssueTypeList types={types} refresh={refresh} /></TabPane> },
        { menuItem: t("accidentSubtype"), render: () => <TabPane><AccidentSubtypeList /></TabPane> },
        { menuItem: t('extraFieldsValue', { value: t('issues').toLowerCase() }), render: () => <TabPane><ExtraFieldList moduleCollection={ModuleCollection.Deviations} messageHeader={t('deviationSettings.extraFieldMessageHeader')} messageBody={t('deviationSettings.extraFieldMessageBody', { value: t('issues').toLowerCase() })} /></TabPane> },
        { menuItem: t('extraFieldsValue', { value: t('accidentIncidentRisk').toLowerCase() }), render: () => <TabPane><ExtraFieldList moduleCollection={ModuleCollection.Accidents} messageHeader={t('deviationSettings.extraFieldMessageHeader')} messageBody={t('deviationSettings.extraFieldMessageBody', { value: t('accidentIncidentRisk').toLowerCase() })} /></TabPane> },
        { menuItem: t("textLinkReporting"), render: () => <TabPane><AccidentAuthority /></TabPane> },
        { menuItem: t("responsible&safety"), render: () => <TabPane><ResponsibleSafetyUserList /></TabPane> },
        { menuItem: t("statistics"), render: () => <TabPane><StatisticConfiguration /></TabPane> },
        { menuItem: t("settings"), render: () => <TabPane><CompanyDeviationSettings /></TabPane> },


    ], [t, refresh, types])

    return <Container><Header dividing>{t('moduleSettings')}</Header><CustomTab menu={{ secondary: true }} panes={panes} /></Container>
}


export default ModuleSettingsPage
